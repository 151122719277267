/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FilterManager } from '@klevu/core';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  Slider,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Close, ExpandMore, ExpandLess } from '@material-ui/icons';

const styles = theme => ({
  overlay: {
    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);',
    height: '50px',
    marginTop: '-50px',
  },
  showMoreButton: {
    color: theme.palette.link.link,
  },
  filterSectionCollapse: {
    padding: ({ appearance }) => {
      if (appearance === 'small') {
        return theme.spacing(0, 2, 2);
      }
      return '0';
    },
  },
  selectedFilters: {
    padding: ({ appearance }) => {
      if (appearance === 'small') {
        return theme.spacing(2, 2, 0);
      }
      return theme.spacing(0, 0, 4);
    },
  },
  filterSectionTitle: {
    padding: ({ appearance }) => {
      if (appearance === 'small') {
        return theme.spacing(1, 2, 1);
      }
      return theme.spacing(0, 0, 1);
    },
  },
  title: {
    ...get(theme, ['palette', 'search', 'filters', 'filter', 'title']),
    marginBottom: '0 !important',
  },
  label: {
    ...get(theme, ['palette', 'search', 'filters', 'filter', 'label']),
  },
});

function OptionCheckbox({ classes, manager, filter, option }) {
  return (
    <FormControlLabel
      style={{ width: '100%' }}
      control={
        <Checkbox
          checked={get(option, 'selected', false)}
          value={get(option, 'name')}
          size="small"
          onChange={() => {
            manager.toggleOption(get(filter, 'key'), get(option, 'name'));
          }}
        />
      }
      label={
        <Typography variant="body1" className={classes.label}>{`${get(option, 'name')} (${get(
          option,
          'count',
        )})`}</Typography>
      }
    />
  );
}

OptionCheckbox.propTypes = {
  classes: PropTypes.object,
  filter: PropTypes.object,
  manager: PropTypes.object,
  option: PropTypes.object,
};

OptionCheckbox.defaultProps = {
  classes: {},
  filter: {},
  manager: {},
  option: {},
};

function SearchFilters({ appearance, classes, filters, manager }) {
  const { t } = useTranslation();
  const [collapseOpen, setCollapseOpen] = useState({});
  const [sliderValues, setSliderValues] = useState({});

  const hidByDefault = appearance === 'small';

  if (!filters) return null;

  const selectedFilters = manager.selectedFilters();

  const handleCollapseClick = key => {
    setCollapseOpen(previousValues => ({
      ...previousValues,
      [key]: !collapseOpen[key],
    }));
  };

  const handleSliderChange = (key, newValues) => {
    setSliderValues(previousValues => ({
      ...previousValues,
      [key]: newValues,
    }));
  };

  const handleSliderChangeCommitted = (key, newValues) => {
    manager.updateSlide(key, newValues[0], newValues[1]);
  };

  const handleClearSelection = () => {
    manager.clearOptionSelections();
    // eslint-disable-next-line no-plusplus
    selectedFilters.forEach(selectedFilter => {
      if (FilterManager.isKlevuFilterResultSlider(selectedFilter)) {
        manager.updateSlide(get(selectedFilter, 'key'), '', '');
      }
    });
  };

  return (
    <>
      {selectedFilters.length > 0 && (
        <div className={classes.selectedFilters}>
          <Typography variant="h3" className={classes.title}>
            {t('KlevuSearch.selectedFilters')}
          </Typography>

          <Box marginTop={2} marginBottom={2}>
            {selectedFilters.map((filter, i) => (
              <Chip
                key={get(filter, 'value', i)}
                size="small"
                label={`${
                  get(filter, 'label') === 'klevu_price' ? t('KlevuSearch.price') : get(filter, 'label')
                }: ${get(filter, 'value')}`}
                style={{ marginBottom: '5px', marginRight: '5px' }}
                clickable
                onDelete={() => {
                  if (FilterManager.isKlevuFilterResultOptions(filter)) {
                    manager.deselectOption(get(filter, 'key'), get(filter, 'value'));
                  }
                  if (FilterManager.isKlevuFilterResultSlider(filter)) {
                    manager.updateSlide(get(filter, 'key'), '', '');
                  }
                }}
                deleteIcon={<Close />}
              />
            ))}
            <Button variant="outlined" onClick={handleClearSelection} style={{ marginTop: '24px', width: '100%' }}>
              {t('KlevuSearch.clearSelections')}
            </Button>
          </Box>
        </div>
      )}
      <List>
        {filters.map((filter, i) => {
          return (
            <FormControl
              key={get(filter, 'key', i)}
              style={{ marginBottom: appearance === 'small' ? '0' : '40px', width: '100%' }}>
              <ListItem
                button={appearance === 'small'}
                onClick={() => {
                  handleCollapseClick(`filter${i}`);
                }}
                className={classes.filterSectionTitle}>
                <ListItemText>
                  <Typography variant="h3" className={classes.title}>
                    {get(filter, 'label') === 'klevu_price' ? t('KlevuSearch.price') : get(filter, 'label')}
                  </Typography>
                </ListItemText>
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                {appearance === 'small' && <>{get(collapseOpen, `filter${i}`) ? <ExpandLess /> : <ExpandMore />}</>}
              </ListItem>
              <Collapse
                in={get(collapseOpen, `filter${i}`, !hidByDefault)}
                timeout="auto"
                unmountOnExit
                className={classes.filterSectionCollapse}>
                <FormGroup>
                  {FilterManager.isKlevuFilterResultOptions(filter) && (
                    <>
                      {appearance === 'small' ? (
                        <>
                          {get(filter, 'options').map((option, j) => (
                            <OptionCheckbox
                              key={get(option, 'name', j)}
                              manager={manager}
                              option={option}
                              filter={filter}
                              classes={classes}
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          {get(filter, 'options')
                            .slice(0, 5)
                            .map((option, j) => (
                              <OptionCheckbox
                                key={get(option, 'name', j)}
                                manager={manager}
                                option={option}
                                filter={filter}
                                classes={classes}
                              />
                            ))}

                          {filter.options.length > 5 && (
                            <>
                              <Collapse in={get(collapseOpen, i, false)} timeout="auto" unmountOnExit>
                                {get(filter, 'options')
                                  .slice(5, filter.options.length)
                                  .map((option, j) => (
                                    <OptionCheckbox
                                      key={get(option, 'name', j)}
                                      manager={manager}
                                      option={option}
                                      filter={filter}
                                      classes={classes}
                                    />
                                  ))}
                              </Collapse>
                              {!get(collapseOpen, i) && <Box className={classes.overlay} />}
                              <Button
                                className={classes.showMoreButton}
                                variant="text"
                                endIcon={get(collapseOpen, i) ? <ExpandLess /> : <ExpandMore />}
                                onClick={() => {
                                  handleCollapseClick(i);
                                }}>
                                {get(collapseOpen, i) ? t('KlevuSearch.showLess') : t('KlevuSearch.showMore')}
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {FilterManager.isKlevuFilterResultSlider(filter) && (
                    <div style={{ padding: '16px 20px 0' }}>
                      <Slider
                        key={get(filter, 'key', i)}
                        value={
                          get(sliderValues, get(filter, 'key')) ?? [
                            parseInt(filter.start || filter.min, 10),
                            parseInt(filter.end || filter.max, 10),
                          ]
                        }
                        max={parseInt(filter.max, 10)}
                        min={parseInt(filter.min, 10)}
                        onChange={(event, value) => {
                          handleSliderChange(filter.key, value);
                        }}
                        onChangeCommitted={(event, value) => {
                          handleSliderChangeCommitted(filter.key, value);
                        }}
                        valueLabelDisplay="on"
                      />
                    </div>
                  )}
                </FormGroup>
              </Collapse>
            </FormControl>
          );
        })}
      </List>
    </>
  );
}

SearchFilters.propTypes = {
  appearance: PropTypes.string,
  classes: PropTypes.object,
  filters: PropTypes.array,
  manager: PropTypes.object,
};

SearchFilters.defaultProps = {
  appearance: 'large',
  classes: {},
  filters: [],
  manager: {},
};

export default withStyles(styles)(SearchFilters);
