/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import { KlevuSearchSorting, KlevuDomEvents, KlevuListenDomEvent, FilterManager } from '@klevu/core';
import {
  AppBar,
  Backdrop,
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useLocation } from '@reach/router';
import LoadingIndicator from '../../../shared/LoadingIndicator';
import SearchFilters from '../../SearchFilters';
import SearchResultItem from '../../SearchResultItem';
import TabPanel from '../../../shared/TabPanel';
import SearchPagination from '../../SearchPagination';
import NoIndex from './NoIndex';
import { getWindowPathName } from '../../../../utils/windowUtils';
import { getSearchResults, getSettings } from '../../../../utils/klevuUtils';
import { searchUsage } from '../../../../utils/analyticUtils';
import SearchFilterDrawer from '../../SearchFilterDrawer';
import SearchSuggestions from '../../SearchSuggestions';

const manager = new FilterManager();

function useQuery() {
  const location = useLocation();
  const searchPath = get(location, 'search');
  return React.useMemo(() => new URLSearchParams(searchPath), [searchPath]);
}

function a11yProps(index) {
  return {
    id: `search-tab-${index}`,
    'aria-controls': `search-tabpanel-${index}`,
  };
}

const styles = theme => ({
  filterGridContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      paddingRight: theme.spacing(4),
    },
  },
  filterDrawerContainer: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  filterSectionTitle: {
    ...get(theme, ['palette', 'search', 'filters', 'title']),
    margin: theme.spacing(1, 0, 4),
  },
  resultContainer: {},
  toolbarContainer: {
    alignItems: 'stretch',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  paginationGridContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  mainContainer: {
    position: 'relative',
    zIndex: 0,
  },
  backdrop: {
    position: 'absolute',
    zIndex: 999,
  },
  gridItem: {
    padding: '0 !important',
  },
});

function SearchResultPage({ classes, pageData }) {
  const { t } = useTranslation();
  const query = useQuery();
  const location = getWindowPathName();

  const tab = query.get('tab');

  const [searchResponseProducts, setSearchResponseProducts] = useState(undefined);
  const [searchResponseContent, setSearchResponseContent] = useState(undefined);
  const [productFilters, setProductFilters] = useState([]);
  const [products, setProducts] = useState([]);
  const [content, setContent] = useState([]);
  const [sorting, setSorting] = useState(KlevuSearchSorting.Relevance);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [totalContentCount, setTotalContentCount] = useState(0);
  const [currentProductPage, setCurrentProductPage] = useState(1);
  const [currentContentPage, setCurrentContentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(tab === 'content' ? 1 : 0);
  const [klevuSettings, setKlevuSettings] = useState();
  const [searchInProgress, setSearchInProgress] = useState(false);

  const pageSize = 36;

  const placeholderImage = get(pageData, ['mainTheme', 'themeStyles', 'favicon']);

  const fetchKlevuSettings = async () => {
    const settings = await getSettings();
    setKlevuSettings(settings);
    return settings;
  };

  const doSearch = async () => {
    setSearchInProgress(true);

    if (!klevuSettings) {
      await fetchKlevuSettings();
      return;
    }

    const searchTermFromUrl = query.get('q');

    const searchResults = await getSearchResults(searchTermFromUrl, klevuSettings, manager, sorting, pageSize);

    const productResults = get(searchResults, 'products');
    const contentResults = get(searchResults, 'cms');

    const productRecords = get(productResults, 'records', []);
    const contentRecords = get(contentResults, 'records', []);

    setSearchResponseProducts(productResults);
    setSearchResponseContent(contentResults);

    setProductFilters(manager.filters ?? []);

    setTotalProductCount(get(productResults, ['meta', 'totalResultsFound'], 0));
    setProducts(productRecords ?? []);

    setTotalContentCount(get(contentResults, ['meta', 'totalResultsFound'], 0));
    setContent(contentResults.records ?? []);

    if (productRecords.length === 0 && contentRecords.length > 0) setSelectedTab(1);

    searchUsage(searchTermFromUrl, totalProductCount + totalContentCount);

    setSearchInProgress(false);
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleProductPageChange = async (event, pageNumber) => {
    const nextResponse = await searchResponseProducts.getPage({
      filterManager: manager,
      pageIndex: pageNumber - 1,
    });

    const nextSearchResult = nextResponse.queriesById('search');

    setProducts(nextSearchResult?.records ?? []);
    setSearchResponseProducts(nextSearchResult);
    setCurrentProductPage(pageNumber);

    scrollTop();
  };

  const handleContentPageChange = async (event, pageNumber) => {
    const nextResponse = await searchResponseContent.getPage({
      filterManager: manager,
      pageIndex: pageNumber - 1,
    });

    const nextSearchResult = nextResponse.queriesById('cms');

    setContent(nextSearchResult?.records ?? []);
    setSearchResponseContent(nextSearchResult);
    setCurrentContentPage(pageNumber);

    scrollTop();
  };

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };

  const handleFilterUpdate = () => {
    setProductFilters(manager.filters);
    doSearch();
  };

  useEffect(() => {
    const stop = KlevuListenDomEvent(KlevuDomEvents.FilterSelectionUpdate, handleFilterUpdate);

    return () => {
      stop();
    };
  }, []);

  useEffect(() => {
    doSearch();
  }, [sorting, query, location, klevuSettings]);

  const pageContentCount = Math.ceil(parseInt(totalContentCount, 10) / parseInt(pageSize, 10));
  const pageProductCount = Math.ceil(parseInt(totalProductCount, 10) / parseInt(pageSize, 10));

  const filtersEnabled = get(klevuSettings, 'klevu_filtersEnabled', true);

  return (
    <>
      <NoIndex />
      <Container className={classes.mainContainer}>
        {products.length > 0 || content.length > 0 ? (
          <>
            <Box marginBottom={1}>
              <AppBar position="static">
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label={t('KlevuSearch.searchTabPanelLabel')}>
                  <Tab
                    label={`${t('KlevuSearch.products')} (${totalProductCount})`}
                    {...a11yProps(0)}
                    disabled={products.length === 0}
                  />
                  <Tab
                    label={`${t('KlevuSearch.cmsContent')} (${totalContentCount})`}
                    {...a11yProps(1)}
                    disabled={content.length === 0}
                  />
                </Tabs>
              </AppBar>
              <Divider />
            </Box>
            <TabPanel value={selectedTab} index={0}>
              <Grid container>
                {filtersEnabled && (
                  <Grid item md={4} lg={3} className={classes.filterGridContainer}>
                    <Typography variant="h2" className={classes.filterSectionTitle}>
                      {t('KlevuSearch.filterResults')}
                    </Typography>
                    <SearchFilters filters={productFilters} manager={manager} settings={klevuSettings} />
                  </Grid>
                )}

                <Grid item xs={12} md={filtersEnabled ? 8 : 12} lg={filtersEnabled ? 9 : 12}>
                  <Box className={classes.resultContainer}>
                    {products.length > 0 ? (
                      <>
                        <Box marginBottom={8}>
                          <Grid container justifyContent="space-between" className={classes.toolbarContainer}>
                            <Grid item>
                              <Grid container justifyContent="space-between">
                                <Grid item>
                                  <Typography variant="body2" display="inline" style={{ marginRight: '10px' }}>
                                    {t('KlevuSearch.orderBy')}:
                                  </Typography>
                                  <Select
                                    size="small"
                                    value={sorting}
                                    onChange={event => setSorting(event.target.value)}>
                                    <MenuItem value={KlevuSearchSorting.Relevance}>
                                      {t('KlevuSearch.orderByRelevance')}
                                    </MenuItem>
                                    <MenuItem value={KlevuSearchSorting.PriceAsc}>
                                      {t('KlevuSearch.orderByPriceAsc')}
                                    </MenuItem>
                                    <MenuItem value={KlevuSearchSorting.PriceDesc}>
                                      {t('KlevuSearch.orderByPriceDesc')}
                                    </MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item>
                                  {filtersEnabled && (
                                    <Box className={classes.filterDrawerContainer}>
                                      <SearchFilterDrawer
                                        filters={productFilters}
                                        manager={manager}
                                        settings={klevuSettings}
                                      />
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item className={classes.paginationGridContainer}>
                              <SearchPagination
                                pageCount={pageProductCount}
                                justify="end"
                                location="top"
                                onChangeHandler={handleProductPageChange}
                                currentPage={currentProductPage}
                                pageSize={pageSize}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid container spacing={3}>
                          {products.map((product, index) => (
                            <Grid
                              item
                              key={get(product, 'sku', index)}
                              xs={6}
                              sm={4}
                              lg={3}
                              className={classes.gridItem}>
                              <SearchResultItem
                                item={product}
                                imageHeight={200}
                                settings={klevuSettings}
                                appearance="grid"
                              />
                            </Grid>
                          ))}
                        </Grid>

                        <SearchPagination
                          pageCount={pageProductCount}
                          onChangeHandler={handleProductPageChange}
                          currentPage={currentProductPage}
                          pageSize={pageSize}
                          scrollTop
                        />
                      </>
                    ) : (
                      <LoadingIndicator />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <SearchPagination
                pageCount={pageContentCount}
                justify="end"
                location="top"
                onChangeHandler={handleContentPageChange}
                currentPage={currentContentPage}
                pageSize={pageSize}
              />
              <List disablePadding>
                {content.map((entry, i) => (
                  <ListItem key={get(entry, 'id', i)} disableGutters divider dense>
                    <SearchResultItem item={entry} imageHeight={130} placeholderImage={placeholderImage} />
                  </ListItem>
                ))}
              </List>
              <SearchPagination
                pageCount={pageContentCount}
                onChangeHandler={handleContentPageChange}
                currentPage={currentContentPage}
                scrollTop
              />
            </TabPanel>
          </>
        ) : (
          !searchInProgress && (
            <>
              {get(klevuSettings, ['klevu_uc_userOptions', 'noResultsOptions', 'messages'], []).map((message, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Typography variant="body1" paragraph key={i}>
                  {get(message, 'message')}
                </Typography>
              ))}

              {get(klevuSettings, ['klevu_uc_userOptions', 'noResultsOptions', 'showPopularKeywords']) && (
                <Box className={classes.resultSection}>
                  <SearchSuggestions
                    searchPage={window.location.hostname}
                    suggestions={klevuSettings.klevu_webstorePopularTerms}
                    title={t('KlevuSearch.popularSearches')}
                  />
                </Box>
              )}
            </>
          )
        )}
        {searchInProgress && (
          <Backdrop open className={classes.backdrop}>
            <LoadingIndicator />
          </Backdrop>
        )}
      </Container>
    </>
  );
}

SearchResultPage.propTypes = {
  classes: PropTypes.object,
  pageData: PropTypes.object,
};

SearchResultPage.defaultProps = {
  classes: {},
  pageData: {},
};

export default withStyles(styles)(SearchResultPage);
