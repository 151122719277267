import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import CommonSearchPagination from './common';

export default function SearchPagination(props) {
  if (isEqual(siteId, siteIds.Viinimaa) || isEqual(siteId, siteIds.Folkofolk)) {
    return <CommonSearchPagination {...props} />;
  }

  return null;
}
